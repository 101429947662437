 /**
 * 操作权限处理
 * lei
 */
 
import store from 'src/store'

export default {
  inserted(el, binding, vnode) {
    const { value } = binding
    // console.log(JSON.stringify(store.getters.acl));
    // console.log(value);
    const permissions = store.getters.acl;
    if (value && value instanceof Array && value.length > 0) {
      var permissionFlag = value[0].split(':')

      // var hasPermissions = permissions.some(permission => {
      //   var hasPermi = permission.children.some(child => {
      //     var hasOpertion = child.grant.some(grant => {
      //       return permissionFlag[1] === grant
      //     })
      //     return hasPermi && permissionFlag[0].includes(child.path) 
      //   })
      //   return hasPermi
      // })
      var hasPermissions = false
      for (var i in permissions) {
        for (var j in permissions[i].children) {
          for (var k in permissions[i].children[j].grant) {
            if (permissions[i].children[j].grant[k] === permissionFlag[1] && permissionFlag[0].includes(permissions[i].children[j].path)) {
              hasPermissions = true
              break
            }
          }
          if (hasPermissions){
            break
          }
        }
        if (hasPermissions){
          break
        }
      }
      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`请设置操作权限标签值`)
    }
  }
}
