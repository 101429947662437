import request from 'src/util/request'

export function userList(data) {
  return request({
    url: '/bas/user/page/get',
    method: 'post',
    data
  })
}

export function addUser(data) {
  return request({
    url: '/bas/user/add',
    method: 'post',
    data
  })
}

export function updateUser(data) {
  return request({
    url: '/bas/user/update',
    method: 'post',
    data
  })
}

export function deleteUser(id) {
  return request({
    url: '/bas/user/delete?id='+id,
    method: 'post'
  })
}

export function carrierList(data) {
  return request({
    url: '/bas/carrier/page/get',
    method: 'post',
    data
  })
}

export function carrierUpdate(body) {
  return request({
    url: '/bas/carrier/update',
    method: 'post',
    data: body
  })
}

export function carrierDelete(id) {
  return request({
    url: '/bas/carrier/delete?id=' + id,
    method: 'post'
  })
}

export function carrierCodeComplete(key) {
  return request({
    url: '/bas/carrier/code/complete?key=' + key,
    method: 'get'
  })
}

export function carrierNameComplete(key) {
  return request({
    url: '/bas/carrier/name/complete?key=' + key,
    method: 'get'
  })
}



export function companyList(data) {
  return request({
    url: '/bas/company/page/get',
    method: 'post',
    data
  })
}

export function addCompany(data) {
  return request({
    url: '/bas/company/add',
    method: 'post',
    data
  })
}

export function updateCompany(data) {
  return request({
    url: '/bas/company/update',
    method: 'post',
    data
  })
}

export function deleteCompany(id) {
  return request({
    url: '/bas/company/delete?id=' + id,
    method: 'post'
  })
}

export function driverList(data) {
  return request({
    url: '/bas/driver/page/get',
    method: 'post',
    data
  })
}

export function addDriver(data) {
  return request({
    url: '/bas/driver/add',
    method: 'post',
    data
  })
}

export function addDriver1(data, companyId) {
  return request({
    url: '/bas/driver/add?companyId='+companyId,
    method: 'post',
    data
  })
}

export function updateDriver(data) {
  return request({
    url: '/bas/driver/update',
    method: 'post',
    data
  })
}

export function deleteDriver(data) {
  return request({
    url: '/bas/driver/delete',
    method: 'post',
    data
  })
}

export function truckList(data) {
  return request({
    url: '/bas/truck/get',
    method: 'post',
    data
  })
}

export function addTruck(data) {
  return request({
    url: '/bas/truck/add',
    method: 'post',
    data
  })
}

export function addTruck1(data, companyId) {
  return request({
    url: '/bas/truck/add?companyId='+companyId,
    method: 'post',
    data
  })
}

export function updateTruck(data) {
  return request({
    url: '/bas/truck/update',
    method: 'post',
    data
  })
}

export function deleteTruck(data) {
  return request({
    url: '/bas/truck/delete',
    method: 'post',
    data
  })
}

export function addDevice(data) {
  return request({
    url: '/bas/device/add',
    method: 'post',
    data
  })
}

export function updateDevice(data) {
  return request({
    url: '/bas/device/update',
    method: 'post',
    data
  })
}

export function deleteDevice(data) {
  return request({
    url: '/bas/device/delete',
    method: 'post',
    data
  })
}

export function deviceList(data) {
  return request({
    url: '/bas/device/get',
    method: 'post',
    data
  })
}

export function consignorList(data) {
  return request({
    url: '/bas/consignor/page/get',
    method: 'post',
    data
  })
}

export function addConsignor(data) {
  return request({
    url: '/bas/consignor/add',
    method: 'post',
    data
  })
}

export function updateConsignor(data) {
  return request({
    url: '/bas/consignor/update',
    method: 'post',
    data
  })
}

export function deleteConsignor(id) {
  return request({
    url: '/bas/consignor/delete?id='+id,
    method: 'post'
  })
}

export function consigneeList(data) {
  return request({
    url: '/bas/consignee/page/get',
    method: 'post',
    data
  })
}

export function addConsignee(data) {
  return request({
    url: '/bas/consignee/add',
    method: 'post',
    data
  })
}

export function updateConsignee(data) {
  return request({
    url: '/bas/consignee/update',
    method: 'post',
    data
  })
}

export function deleteConsignee(id) {
  return request({
    url: '/bas/consignee/delete?id='+id,
    method: 'post'
  })
}

export function policyList(data) {
  return request({
    url: '/bas/policy/page/get',
    method: 'post',
    data
  })
}

export function addPolicy(data) {
  return request({
    url: '/bas/policy/add',
    method: 'post',
    data
  })
}

export function updatePolicy(data) {
  return request({
    url: '/bas/policy/update',
    method: 'post',
    data
  })
}

export function refreshPolicy() {
  return request({
    url: '/bas/policy/refresh',
    method: 'post'
  })
}

export function driverComplete(key) {
  return request({
    url: '/bas/driver/complete?key='+key,
    method: 'get'
  })
}

export function truckComplete(key, companyId) {
  return request({
    url: '/bas/truck/_suggest?key='+key+"&companyId="+companyId,
    method: 'get'
  })
}
export function truckTypeGet() {
  return request({
    url: '/bas/truck/type/_get',
    method: 'get'
  })
}

export function roleGet() {
  return request({
    url: '/bas/user/role/get',
    method: 'get'
  })
}

export function checkName(name) {
  return request({
    url: '/bas/user/account/check?name=' + name,
    method: 'get'
  })
}

export function companyGet() {
  return request({
    url: '/bas/company/all/get',
    method: 'get'
  })
}

export function areaComplete(key) {
  return request({
    url: '/bas/location/_suggest?key='+key,
    method: 'post'
  })
}

export function consigneeComplete(key, companyId) {
  return request({
    url: '/bas/consignee/_suggest?key='+key+"&companyId="+companyId,
    method: 'post'
  })
}

export function consignorComplete(key, companyId) {
  return request({
    url: '/bas/consignor/_suggest?key='+key+"&companyId="+companyId,
    method: 'post'
  })
}

export function addConsignee1(data, companyId) {
  return request({
    url: '/bas/consignee/_add?companyId='+companyId,
    method: 'post',
    data
  })
}


export function addConsignor1(data, companyId) {
  return request({
    url: '/bas/consignor/_add?companyId='+companyId,
    method: 'post',
    data
  })
}


const carrierImport = location.protocol + '//' + location.hostname +(!isUseProxy?':6080':'')+ '/bas/carrier/upload'

const driverImport = location.protocol + '//' + location.hostname +(!isUseProxy?':6080':'')+ '/bas/driver/upload'

const consignorImport = location.protocol + '//' + location.hostname +(!isUseProxy?':6080':'')+ '/bas/consignor/upload'

const consigneeImport = location.protocol + '//' + location.hostname +(!isUseProxy?':6080':'')+ '/bas/consignee/upload'

const truckImport = location.protocol + '//' + location.hostname +(!isUseProxy?':6080':'')+ '/bas/truck/upload'

const carrierImportTemplate = location.protocol + '//' + location.hostname +(!isUseProxy?':6080':'')+ '/bas/carrier/tpl/download'

const driverImportTemplate = location.protocol + '//' + location.hostname +(!isUseProxy?':6080':'')+ '/bas/driver/tpl/download'

const truckImportTemplate = location.protocol + '//' + location.hostname +(!isUseProxy?':6080':'')+ '/bas/truck/tpl/download'

const consignorImportTemplate = location.protocol + '//' + location.hostname +(!isUseProxy?':6080':'')+ '/bas/consignor/tpl/download'
const consigneeImportTemplate = location.protocol + '//' + location.hostname +(!isUseProxy?':6080':'')+ '/bas/consignee/tpl/download'

export {
  carrierImport, 
  driverImport, 
  truckImport, 
  consignorImport,
  consigneeImport,
  carrierImportTemplate,
  driverImportTemplate, 
  truckImportTemplate,
  consignorImportTemplate,
  consigneeImportTemplate,
}