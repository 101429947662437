const state = {
    // options: [{route: "/admin/overview", name: "主页"}],
    // activeIndex: '/admin/overview'
    options: [],
    activeIndex: ''
}
// 定义所需的 mutations
const mutations = {
    // 添加tabs
    add_tabs (state, data) {
        let isExist = this.state.tabs.options.findIndex(item=>{
          return item.route === data.route
        })
        if (isExist === -1){
          this.state.tabs.options.push(data);
        }
    },
    // 更新tabs
    edit_tabs (state, data) {
      this.state.tabs = data
    },
    clean_tabs (state) {
      this.state.tabs.options = []
      this.state.tabs.activeIndex = ''
    },
    // 删除tabs
    delete_tabs (state, route) {
      let index = 0;
      for (let option of state.options) {
        if (option.route === route) {
          break;
        }
        index++;
      }
      this.state.tabs.options.splice(index, 1);
    },
    // 设置当前激活的tab
    set_active_index (state, index) {
      this.state.tabs.activeIndex = index;
    }
}

export default {
    namespaced: true,
    state,
    mutations
  }