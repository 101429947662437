var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page"},[_c('app-navbar'),_c('div',{staticClass:"wrapper wrapper-full-page"},[_c('div',{staticClass:"full-page login-page section-image"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-lg-4 col-md-6 ml-auto mr-auto"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{attrs:{"type":"login"}},[_c('h3',{staticClass:"header text-center",attrs:{"slot":"header"},slot:"header"},[_vm._v("登录")]),_c('ValidationProvider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"hasSuccess":passed,"name":"username","error":failed ? '账号必填': null,"addon-left-icon":"nc-icon nc-single-02","placeholder":"账号"},model:{value:(_vm.loginForm.username),callback:function ($$v) {_vm.$set(_vm.loginForm, "username", $$v)},expression:"loginForm.username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"hasSuccess":passed,"name":"password","error":failed ? '密码必填': null,"addon-left-icon":"nc-icon nc-key-25","placeholder":"密码","type":"password"},model:{value:(_vm.loginForm.password),callback:function ($$v) {_vm.$set(_vm.loginForm, "password", $$v)},expression:"loginForm.password"}})]}}],null,true)}),_c('br'),_c('p-checkbox',[_vm._v(" 为了您的账户安全，请及时退出系统 ")]),_c('p-button',{staticClass:"mb-3",attrs:{"slot":"footer","native-type":"submit","type":"warning","round":"","block":""},slot:"footer"},[_vm._v("登录")])],1)],1)]}}])})],1)])]),_c('app-footer'),_c('div',{staticClass:"full-page-background",staticStyle:{"background-image":"url(static/img/background/background-2.jpg)"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }