import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from 'src/store'
import { getToken } from 'src/util/auth'

// create an axios instance
const service = axios.create({
  baseURL: location.protocol + '//' + location.hostname,
  // baseURL: 'http://47.114.176.146',
  //baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['x-auth-Token'] = getToken()
    }
    if (!isUseProxy){
      if (config.url) {
        const apiUrl = config.url
        if (apiUrl.startsWith('/bas')) {
          config['baseURL'] = location.protocol + '//' + location.hostname + ':6080'
        } else if (apiUrl.startsWith('/oms')) {
          config['baseURL'] = location.protocol + '//' + location.hostname + ':6060'
        } else if (apiUrl.startsWith('/rise')) {
          config['baseURL'] = location.protocol + '//' + location.hostname + ':6070'
        } else if (apiUrl.startsWith('/gis')) {
          config['baseURL'] = location.protocol + '//' + location.hostname + ':6090'
        }
      }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response && !response.data) {
      return response
    }
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.action && res.action !== 'ok') {
      Message({
        message: res.err.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 401) {
        // to re-login
        MessageBox.confirm('登录已失效，请重新登录', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/logout').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
