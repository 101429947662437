const getters = {
  token: state => state.user.token,
  account: state => state.user.account,
  roles: state => state.user.roles,
  acl: state => state.user.acl,
  feature: state => state.user.feature,
  uid: state => state.user.uid,
  companyId: state => state.user.companyId,
}
export default getters
