import Vue from 'vue'
import VueRouter from 'vue-router'
import initProgress from 'src/progressbar';


Vue.use(VueRouter)



const originalPush = VueRouter.prototype.push
VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch(err => err)
  }
  const originalReplace = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch(err => err)
  }

import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// Pages
import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import TimeLine from 'src/components/Dashboard/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Dashboard/Views/Login/Login.vue'
import Register from 'src/components/Dashboard/Views/Pages/Register.vue'
import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'

// Components pages
import Buttons from 'src/components/Dashboard/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Dashboard/Views/Components/GridSystem.vue'
import Panels from 'src/components/Dashboard/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Dashboard/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Dashboard/Views/Components/Notifications.vue'
import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'
import Typography from 'src/components/Dashboard/Views/Components/Typography.vue'

// Forms pages
const RegularForms  = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/RegularForms.vue')
const ExtendedForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ExtendedForms.vue');
const ValidationForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ValidationForms.vue')
const Wizard = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/Wizard.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');
const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/ExtendedTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/PaginatedTables.vue');
// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/GoogleMaps.vue')
const FullScreenMap = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/FullScreenMap.vue')
const VectorMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/VectorMapsPage.vue');

// Calendar
import Calendar from 'src/components/Dashboard/Views/Calendar/CalendarRoute.vue'
// Charts
const Charts = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Charts.vue')


const OrderList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Oms/Order.vue');
const OrderTruck = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Oms/OrderTruck.vue');
const OrderImport = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Oms/OrderImport.vue');
const OrderTimeline = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Oms/TimeLine.vue');
const OrderDetail = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Oms/OrderDetail.vue');
const OrderClockImport = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Oms/ClockImport.vue');
const OrderPlanEdit = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Oms/OrderPlanEdit.vue');
const OrderPlan = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Oms/OrderPlan.vue');

const CcList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Cc/Cc.vue');

const ShipList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Str/Ship.vue');
const ShipTimeline = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Str/TimeLine.vue');
const ShipClockImport = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Str/ClockImport.vue');

const UserList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/User/User.vue');
const CompanyList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Company/Company.vue');
const DriverList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Driver/Driver.vue');
const DriverImport = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Driver/DriverImport.vue');
const TruckList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Truck/Truck.vue');
const TruckGis = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/gis/Gis.vue');
const DeviceList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/device/Device.vue');
const TruckImport = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Truck/TruckImport.vue');
const CarrierImport = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Carrier/CarrierImport.vue');
const CarrierList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Carrier/Carrier.vue');

const ConsignorList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Consignor/Consignor.vue');
const ConsigneeList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Consignee/Consignee.vue');

const ConsignorImport = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Consignor/ConsignorImport.vue');
const ConsigneeImport = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Consignee/ConsigneeImport.vue');



const PolicyList = () => import(/* oms: "tables" */ 'src/components/Dashboard/Views/Policy/Policy.vue');
let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }]
}

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

let oms = {
  path: '/oms',
  component: DashboardLayout,
  redirect: '/oms/order',
  children: [
    {
      path: 'order/list',
      name: '订单管理',
      component: OrderList
    },{
      path: 'order/tracking',
      name: '订单跟踪',
      component: OrderTruck
    },{
      path: 'order/import',
      name: '订单导入',
      component: OrderImport
    },{
      path: 'order/clock/import',
      name: '订单打卡',
      component: OrderClockImport
    },{
      path: 'truck',
      name: '订单时间轴',
      component: OrderTimeline
    },{
      path: 'detail',
      name: '委托单',
      component: OrderDetail
    },{
      path: 'order/planEdit',
      name: '订单计划制作',
      component: OrderPlanEdit
    },{
      path: 'order/plan',
      name: '计划列表',
      component: OrderPlan
    }]
}
let cc = {
  path: '/oms',
  component: DashboardLayout,
  redirect: '/oms/cc/list',
  children: [
    {
      path: 'cc/list',
      name: '中转发运',
      component: CcList
    },]
}
let carrier = {
  path: '/bas',
  component: DashboardLayout,
  redirect: '/bas/carrier/list',
  children: [
    {
      path: 'carrier/list',
      name: '承运商列表',
      component: CarrierList
    },
    {
      path: 'carrier/import',
      name: '承运商导入',
      component: CarrierImport
    }]
}

let consignor = {
  path: '/bas',
  component: DashboardLayout,
  redirect: '/bas/consignor/list',
  children: [
    {
      path: 'consignor/list',
      name: '发货方列表',
      component: ConsignorList
    },{
      path: 'consignor/import',
      name: '发货方导入',
      component: ConsignorImport
    }]
}

let consignee = {
  path: '/bas',
  component: DashboardLayout,
  redirect: '/bas/consignee/list',
  children: [
    {
      path: 'consignee/list',
      name: '收货方列表',
      component: ConsigneeList
    },{
      path: 'consignee/import',
      name: '收货方导入',
      component: ConsigneeImport
    }]
}

let policy = {
  path: '/bas',
  component: DashboardLayout,
  redirect: '/bas/policy/list',
  children: [
    {
      path: 'policy/list',
      name: '安全策略',
      component: PolicyList
    }]
}

let user = {
  path: '/bas',
  component: DashboardLayout,
  redirect: '/bas/user/list',
  children: [
    {
      path: 'user/list',
      name: '用户管理',
      component: UserList
    }]
}

let company = {
  path: '/bas',
  component: DashboardLayout,
  redirect: '/bas/company/list',
  children: [
    {
      path: 'company/list',
      name: '公司列表',
      component: CompanyList
    }]
}

let driver = {
  path: '/bas',
  component: DashboardLayout,
  redirect: '/bas/driver/list',
  children: [
    {
      path: 'driver/list',
      name: '司机列表',
      component: DriverList
    },{
      path: 'driver/import',
      name: '司机导入',
      component: DriverImport
    }]
}

let device = {
  path: '/bas',
  component: DashboardLayout,
  redirect: '/bas/device/list',
  children: [
    {
      path: 'device/list',
      name: '定位设备',
      component: DeviceList
    }]
}

let truck = {
  path: '/bas',
  component: DashboardLayout,
  redirect: '/bas/truck/list',
  children: [
    {
      path: 'truck/list',
      name: '车辆列表',
      component: TruckList
    },{
      path: 'truck/import',
      name: '车辆导入',
      component: TruckImport
    }]
}

let gis = {
  path: '/gis',
  component: DashboardLayout,
  redirect: '/bas/location/list',
  children: [
    {
      path: 'location/list',
      name: '车辆定位',
      component: TruckGis
    }]
}


let str = {
  path: '/rise',
  component: DashboardLayout,
  redirect: '/rise/ship/list',
  children: [
    {
      path: 'ship/list',
      name: '运单管理',
      component: ShipList
    },{
      path: 'ship/clock/import',
      name: '运单打卡',
      component: ShipClockImport
    },{
      path: 'truck',
      name: '运单时间轴',
      component: ShipTimeline
    },]
}

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  registerPage,
  lockPage,
  oms,
  cc,
  user,
  carrier,
  company,
  driver,
  consignor,
  consignee,
  policy,
  truck,
  device,
  gis,
  str,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: '主页(演示数据)',
        component: Overview
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  {path: '*', component: NotFound}
];
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})

initProgress(router);
export default router
