import router from './router'
import store from './store'
import { getToken } from 'src/util/auth' // get token from cookie

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
    } else {
      // if (to.path === "/admin/overview") {
      //   store.commit('tabs/set_active_index', "/admin/overview");
      // }
      next({replace: true })
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
    }
  }
})

// router.afterEach(() => {
//   // finish progress bar
//   // NProgress.done()
// })
