<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                <card type="login">
                  <h3 slot="header" class="header text-center">登录</h3>
          <ValidationProvider
                    name="username"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                  <fg-input v-model="loginForm.username" :hasSuccess="passed" name="username" :error="failed ? '账号必填': null" addon-left-icon="nc-icon nc-single-02"
                            placeholder="账号"></fg-input>
</ValidationProvider>
<ValidationProvider
                    name="password"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                  <fg-input v-model="loginForm.password" :hasSuccess="passed" name="password" :error="failed ? '密码必填': null" addon-left-icon="nc-icon nc-key-25" placeholder="密码"
                            type="password"></fg-input>
</ValidationProvider>


                  <br>

                  <p-checkbox>
                    为了您的账户安全，请及时退出系统
                  </p-checkbox>

                  <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3">登录</p-button>
                </card>
              </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button } from 'src/components/UIComponents';
  import AppNavbar from '../Pages/Layout/AppNavbar'
  import AppFooter from '../Pages/Layout/AppFooter'

  import { extend } from "vee-validate";
  import { required, email, min } from "vee-validate/dist/rules"

  import { login } from 'src/api/user'

  extend("email", email)
  extend("required", required)
  extend("min", min)


  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      submit() {
        var that = this
        this.$store.dispatch('user/login', this.loginForm)
            .then(res => {
              var defaultPath = '*'
              var defaultName = '404'
              this.$store.commit('tabs/clean_tabs', {});
              var _tabs = localStorage.getItem('vuex');
              let redirect = decodeURIComponent(this.$route.query.redirect)
              let isExist = false
              if (res.feature[0].children.length === 0) {
                defaultPath = res.feature[0].path
                this.$store.commit('tabs/add_tabs', {route: res.feature[0].path, name: res.feature[0].name});
                this.$store.commit('tabs/set_active_index', res.feature[0].path);
              } else {
                defaultPath = res.feature[0].children[0].path
                this.$store.commit('tabs/add_tabs', {route: res.feature[0].children[0].path, name: res.feature[0].children[0].name});
                this.$store.commit('tabs/set_active_index', res.feature[0].children[0].path);
              }
              if (redirect) {
                for (var i in res.feature) {
                  for (var j in res.feature[i].children) {
                    if (res.feature[i].children[j].path === redirect) {
                      isExist = true
                      defaultPath = res.feature[i].children[j].path
                      this.$store.commit('tabs/add_tabs', {route: res.feature[i].children[j].path, name: res.feature[i].children[j].name});
                      this.$store.commit('tabs/set_active_index', defaultPath);
                      break
                    }
                  }
                  if (isExist) {
                    break
                  }
                }
              } 
              if (_tabs) {
                var tabs_obj = JSON.parse(_tabs)
                that.$router.replace({ path: tabs_obj.tabs.activeIndex || defaultPath })
              } else {
                that.$router.push({ path: defaultPath })
              }
              
            })
            .catch(() => {
            })
      }
    },
    data() {
      return {
        loginForm: {
          username: '',
          password: ''
        }
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style lang="scss" scoped>
.login-page .card-login .input-group:last-child {
    margin-bottom: 10px;
}
</style>
