<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications transition-name="notification-list" transition-mode="out-in">

    </notifications>
    <router-view name="header"></router-view>
    <transition name="fade"
                mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
  // Loading some plugin css asynchronously
  import 'sweetalert2/dist/sweetalert2.css'
  import 'vue-notifyjs/themes/default.css'
  var isClosed = true
  export default {
    
  mounted() {
    // let beginTime = 0; //开始时间
    // let differTime = 0; //时间差
    // window.onunload = function() {
    //   differTime = new Date().getTime() - beginTime;
    //   if (differTime > 0) {
    //     localStorage.removeItem('vuex')
    //     console.log("这是关闭");
    //   } else {
        
    //     console.log("这是刷新");
    //   }
    // };

    // window.onbeforeunload = function() {
    //   beginTime = new Date().getTime();
    // };
  },
  methods: {
     
    }
  }
</script>
<style lang="scss">
.swal2-container.swal2-center {
  z-index: 99999999 !important;
}
</style>
