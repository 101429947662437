<template>
  <div class="tabs">
      <el-tabs
    v-model="activeIndex"
    @tab-click="tabClick"
    type="card"
    v-if="options.length"
    @tab-remove="tabRemove">
    <el-tab-pane
      lazy
      :key="item.route"
      :closable="index==0?false:true"
      v-for="(item, index) in options"
      :label="item.name"
      :name="item.route">
    </el-tab-pane>
  </el-tabs>
  </div>
</template>
<script>
  export default {
    data() {
        return {

        }
    },
    computed: {
        options () {
        return this.$store.state.tabs.options;
        },
        activeIndex: {
        get () {
            return this.$store.state.tabs.activeIndex;
        },
        set (val) {
            this.$store.commit('tabs/set_active_index', val);
        }
        }
    },
    watch: {
        '$route'(to) {
            let flag = false;
            console.log("to=="+to.path)
            for (let option of this.options ) {
                if (option.route === to.path) {
                flag = true;
                this.$store.commit('tabs/set_active_index', to.path);
                break
                }
            }
            if (!flag) {
                this.$store.commit('tabs/add_tabs', {route: to.path, name: to.name});
                this.$store.commit('tabs/set_active_index', to.path);
            }
        }
    },
    methods: {
        tabClick (tab) {
            let path = this.activeIndex;
            this.$router.replace({path: path});
        },
        tabRemove (targetName) {
            // 首页不可删除
            if(targetName == '/') {
                return;
            }
            this.$store.commit('tabs/delete_tabs', targetName);
            if (this.activeIndex === targetName) {
                // 设置当前激活的路由
                if (this.options && this.options.length >= 1) {
                this.$store.commit('tabs/set_active_index', this.options[this.options.length-1].route);
                this.$router.replace({path: this.activeIndex});
                } else {
                this.$router.replace({path: '/'});
                }
            }
        }
    }
  }

</script>

<style lang="scss" scoped>
.tabs {
    margin-top: 64px;
}
/deep/ .el-tabs__content {
    padding-top: 0px !important;
}
// /deep/ .el-tabs__item {
//   background: #9a9a9a !important;
// }

/deep/ .el-tabs__nav-scroll{
/*padding-left: 15px !important;*/
  background: #ffffff;
}
/*/deep/ .el-tabs__item:nth-child(1) span{*/
/*       display: none !important;*/
/*  }*/
  /deep/ .el-tabs__active-bar{
    // color: #6bd098 !important;
    // background-color: #6bd098 !important;
    color: #ffffff !important;
    background-color: #ffffff !important;
  }
/deep/ .el-tabs__item.is-active {
  color: #ffffff !important;
  background-color: #66615b!important;
}

/deep/ .el-tabs__nav-scroll {
  /* padding-left: 15px !important; */
  // background: #9a9a9a !important;
  background: #f4f3ef;
}
  /deep/ .el-tabs__item:hover {
    color: #6BD098 !important;
  }

</style>
