export default [
  {
    name: '主页(演示数据)',
    icon: 'el-icon-s-home',
    path: '/admin/overview',
    shortName: '主'
  },
  {
    name: '客户订单',
    icon: 'el-icon-s-order',
    children: [
      {
        name: '订单管理',
        path: '/oms/order/list',
        shortName: '订'
      },
      {
        name: '订单导入',
        path: '/oms/order/import',
        shortName: '导'
      },
      {
        name: '订单打卡',
        path: '/oms/order/clock/import',
        shortName: '卡'
      }
    ]
  },
  {
    name: '车次运单',
    icon: 'iconfont icon-UI_icon_checi',
    collapsed: true,
    children: [{
      name: '运单管理',
      path: '/str/ship/list',
      shortName: '车'
    },
    {
      name: '运单打卡',
      path: '/str/ship/clock/import',
      shortName: '卡'
    }
  ]
  },
  {
    name: '集并中转',
    icon: 'iconfont icon-zhongzhuan',
    collapsed: true,
    children: [{
      name: '中转发运',
      path: '/oms/cc/list',
      shortName: '转'
    },
    ]
  },
  {
    name: '承运商管理',
    icon: 'iconfont icon-rengonggaichengyunshang',
    collapsed: true,
    children: [
      {
      name: '承运商列表',
      path: '/bas/carrier/list',
      shortName: '承'
    },
    {
      name: '承运商导入',
      path: '/bas/carrier/import',
      shortName: '导'
    }
    ]
  },
  {
    name: '司机管理',
    icon: 'iconfont icon-sijiguanli',
    collapsed: true,
    children: [{
      name: '司机列表',
      path: '/bas/driver/list',
      shortName: '司'
    },
	{
      name: '司机导入',
      path: '/bas/driver/import',
      shortName: '导'
    }
    ]
  },
  {
    name: '车辆管理',
    icon: 'iconfont icon-cheliang',
    collapsed: true,
    children: [{
      name: '车辆列表',
      path: '/bas/truck/list',
      shortName: '车'
    },
	{
      name: '车辆导入',
      path: '/bas/truck/import',
      shortName: '导'
    }
    ]
  },
  {
    name: '发货方管理',
    icon: 'iconfont icon-shoufahuorenxinxiguanli',
    collapsed: true,
    children: [{
      name: '发货方列表',
      path: '/bas/consignor/list',
      shortName: '发'
    },
	{
      name: '发货方导入',
      path: '/bas/consignor/import',
      shortName: '导'
    }
    ]
  },
  {
    name: '收货方管理',
    icon: 'iconfont icon-shouhuo',
    collapsed: true,
    children: [{
      name: '收货方列表',
      path: '/bas/consignee/list',
      shortName: '收'
    },
	{
      name: '收货方导入',
      path: '/bas/consignee/import',
      shortName: '导'
    }
    ]
  },
  {
    name: '用户中心',
    icon: 'iconfont icon-yonghu2',
    collapsed: true,
    children: [{
      name: '用户管理',
      path: '/bas/user/list',
      shortName: '用'
    },
	{
      name: '安全策略',
      path: '/bas/policy/list',
      shortName: '安'
    }
    ]
  },
  {
    name: '公司管理',
    icon: 'iconfont icon-gongsi-',
    collapsed: true,
    children: [{
      name: '公司列表',
      path: '/bas/company/list',
      shortName: '公'
    },
    ]
  },
]
