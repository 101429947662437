import { login } from 'src/api/user'
import { getToken, setToken, removeToken } from 'src/util/auth'

const state = {
  token: getToken(),
  account: '',
  avatar: '',
  introduction: '',
  roles: [],
  acl: [],
  feature: [],
  uid: '',
  companyId:undefined
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_ACCOUNT: (state, account) => {
    state.account = account
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ACL: (state, acl) => {
    state.acl = acl
  },
  SET_FEATURE: (state, feature) => {
    state.feature = feature
  },
  SET_UID: (state, uid) => {
    state.uid = uid
  },
  SET_COMPANY_ID: (state, companyId) => {
    state.companyId = companyId
  }
  
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        // response.feature[1].children.push({name: "订单计划制作", path: "/oms/order/planEdit", shortName: "制"})
        response.feature[6].children.push({name: "定位设备", path: "/bas/device/list", shortName: "DEV"})
        commit('SET_TOKEN', response.token)
        commit('SET_ACL', response.feature)
        commit('SET_FEATURE', response.feature)
        commit('SET_UID', response.uid)
        commit('SET_COMPANY_ID', response.companyId)
        commit('SET_ACCOUNT', username.trim())
        setToken(response.token)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { roles, name, avatar, introduction } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }

        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        commit('SET_INTRODUCTION', introduction)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ACL', '')
        commit('SET_FEATURE', '')
        commit('SET_UID', "")
        commit('SET_COMPANY_ID', "")
        commit('SET_ROLES', "")
        commit('SET_ACCOUNT', "")
        removeToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
