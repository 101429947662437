<template>
  <div class="user">
    <div class="photo">
      <img src="static/img/faces/face-2.jpg" alt="user avatar"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
         <span>
           欢迎{{$store.getters.account}}登陆
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <a href="#">
                <span class="sidebar-mini-icon">改</span>
                <span class="sidebar-normal" @click="editPassword">修改密码</span>
              </a>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
    <el-dialog title="密码修改" :visible.sync="dialogEditFormVisible" :modal-append-to-body="false">
      <form class="form-horizontal">
        <div class="row">
          <label class="col-md-3 col-form-label">新密码</label>
          <div class="col-md-9">
            <fg-input placeholder="新密码" v-model="editForm.password"></fg-input>
          </div>
          <!-- <label class="col-md-3 col-form-label">密码确认</label>
          <div class="col-md-9">
            <fg-input placeholder="密码确认" v-model="editForm.newPassword"></fg-input>
          </div> -->
        </div>
      </form>
      <div slot="footer" class="dialog-footer">
        <p-button @click="dialogEditFormVisible = false">取 消</p-button>
        <p-button type="primary" @click="editPasswordConfirm">确 定</p-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions'
  import {updateUser} from "src/api/bas";

  export default {
    components: {
      CollapseTransition
    },
    data() {
      return {
        isClosed: true,
        dialogEditFormVisible: false,
        editForm: {
          password: '',
          id: this.$store.getters.uid
        },
      }
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      },
      editPassword () {
        this.editForm.password = ''
        this.dialogEditFormVisible  = true
      },
      editPasswordConfirm () {
        updateUser(this.editForm).then( res => {
        this.$swal({
          title: "已修改",
          timer: 3000,
          // text: 'Your imaginary file has been deleted.',
          icon: "success",
          confirmButtonClass: "btn btn-success btn-fill",
          buttonsStyling: false
        });
        this.dialogEditFormVisible = false
      })
      }
    }
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
